import React from "react"
import Helmet from "react-helmet"
import FooterLinksSingle from "@tightrope/footerlinks/footerlinks_single.js"
import Styles from "./css/xpity.module.scss"


class XpiThankYou extends React.Component {
  render() {
    return (
<>
      <Helmet>
       <style type="text/css">{`

           body {
             background-color: #fff;
                }
         `}
       </style>
         <title>Thank You for Installing PDFtab</title>
         </Helmet>

    <section>
      <div className = {Styles.xpity}>
        <img className={Styles.aUp} src="/img/arrow-up.png" />
      <div className={Styles.container}>
        <img className={Styles.logo} src="/img/logo.png"/>
        <h1 className={Styles.header}>Thank You for Installing PDFtab</h1>
        <h3 className={Styles.subhead}>Click on the .PDF icon to open PDFtab at any time</h3>

        <div className={Styles.gridContainer}>
          <div className={Styles.left}>

            <h4 className={Styles.features}>Free PDF Editing Tools</h4>
            <p className={Styles.feature}>Edit text, add photos and shapes in the Edit tab along with many other free tools</p>

        <h4 className={Styles.features}>Easy Drag and Drop</h4>
        <p className={Styles.feature}>Drag and drop .pdf files directly into the uploader to begin working</p>

        <h4 className={Styles.features}>Open .pdf files in Editor</h4>
        <p className={Styles.feature}>PDFtab will automatically detect .pdf files and open them in the editor for your convenience</p>
        </div>

        <div className={Styles.right}>
        <video src="/img/pdftab-ff-animation.mp4" autoPlay muted loop/>
        </div>
</div>
      <a href="/img/pdftab-sample.pdf" className={Styles.sample}>Download our sample PDF to learn how to use all the features of pdf.live!</a>
      </div>

            <FooterLinksSingle></FooterLinksSingle>
    </div>
</section>
</>

    )
  }
}
export default XpiThankYou
